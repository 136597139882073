.contentWrapper {
  padding: var(--bdo-scale-4xl) var(--bdo-scale-16xl);
  background-color: var(--mono-lightest) !important;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow-y: scroll;
}

.dashboardWrapper {
  flex: 1;
  display: block;
}
.pageHeaderStyles {
  font-size: var(--bdo-scale-lg);
  margin: 0;
  margin-bottom: var(--bdo-scale-md);
}
.tableLoaderStyles {
  color: var(--bdo-primary) !important;
}
.adminPanelTabs [class*="ant-tabs-tab-btn"] {
  color: var(--bdo-charcoal);
}
.adminPanelTabs [class*="ant-tabs-tab-active"] {
  color: var(--bdo-primary);
}
.adminPanelTabs [class*="ant-tabs-tab-btn"]:hover {
  color: var(--bdo-primary) !important;
}
.iconCheck {
  color: var(--bdo-success);
}
.iconClose {
  color: var(--bdo-primary);
}
.tabActionButtons {
  background-color: transparent !important;
}
