.tableLoaderStyles {
  color: var(--bdo-primary) !important;
}
.adminUpdatesListingWrapper {
  max-height: 424px;
  overflow: auto;
}
.updatesListingMainWrapperStyles {
  border-top: 1px solid var(--mono-lighter);
}
.adminUpdatesListingWrapper [class*="ant-collapse-item"] {
  background-color: var(--bdo-white) !important;
}
.adminUpdatesListingWrapper [class*="ant-collapse-item"]:hover {
  background-color: var(--mono-lighter) !important;
}
.adminUpdatesListingWrapper [class*="ant-collapse-item-active"] {
  background-color: var(--mono-lightest) !important;
}
.adminUpdatesPanel [class*="ant-collapse-content-box"] {
  margin: 0 !important;
  padding: 17.5px !important;
}
.adminUpdatesPanel [class*="ant-collapse-content-box"] ol {
  margin: 0 !important;
}
ol li {
  max-width: 80ch;
}
.accordionHeaderWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
