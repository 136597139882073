@charset "utf-8";

/* Card */
.bdo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: white;
  background-clip: border-box;
  border: 1px solid var(--bdo-shade);
  margin-bottom: var(--bdo-xxxl);
}
.bdo-card:is(*:last-child) {
  margin-bottom: 0;
}
.bdo-card:is([class*="bdo-col-parent-"]) {
  flex-direction: row;
}

@media (max-width: 576px) {
  .bdo-page > .bdo-page-content,
  .bdo-card {
    margin: 0;
  }

  .bdo-card {
    border-left: none;
    border-right: none;
  }

  .bdo-card + .bdo-card {
    border-top: none;
  }
}

/* Card elements */
[class*="bdo-card"] > :is(h1, h2, h3, h4, h5, h6) {
  font-size: var(--bdo-xl);
  margin-bottom: var(--bdo-xl);
  font-weight: var(--bdo-text-medium) !important;
}

[class*="bdo-card"] > header {
  min-height: var(--bdo-xxxl);
  display: flex;
  align-items: center;
  padding: 0 var(--bdo-xxxl);
  border-bottom: 1px solid var(--bdo-shade);
  gap: 0.5em;
}
[class*="bdo-card"] > header:has(+ footer) {
  border-bottom: none;
}
[class*="bdo-card"] > header,
[class*="bdo-card"] > header > :is(*) {
  text-transform: uppercase;
  font-size: var(--bdo-xs);
  line-height: 1;
}

[class*="bdo-card"] > header > :is(*) {
  margin: 0;
}

.bdo-card .bdo-card-body > header {
  margin: calc(var(--bdo-xxxl) * -1) calc(var(--bdo-xxxl) * -1) var(--bdo-xxxl);
}

/* Menu / Navigation - generic */
:is(nav ul, nav menu) .active {
  color: var(--bdo-colour-primary);
  font-weight: var(--bdo-text-medium) !important;
}

/* Empty containers */
[class*="bdo-card"]:empty {
  display: none;
}
