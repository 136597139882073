.wrapperStyles {
  position: relative;
  border-right: 1px solid var(--border-semi-transparent-light, #3333331f);
  background: var(--surface-white, #fff);
  overflow-x: auto;
  overflow-y: hidden;
}
.evidenceDrawer {
  background-color: white;
}
.evidenceDrawer [class*="ant-drawer-wrapper-body"] {
  background-color: white;
}
.evidenceDrawer [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
  color: var(--bdo-charcoal);
}
.evidenceDrawer [class*="ant-drawer-body"] {
  padding: 0;
  overflow-y: hidden;
}
.dragableButtonStyles {
  position: absolute;
  right: -17.5px;
  top: calc(50% - 23.5px);
  display: flex;
  width: 27px;
  height: 47px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.pdfViewIframeStyles {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: white;
  overflow-x: auto;
}
.evidenceHeader {
  width: 100%;
  padding: var(--bdo-scale-xxs) var(--bdo-scale-md);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--bdo-scale-lg);
  font-weight: 700;
}
.evidenceHeader h2 {
  max-width: 35vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.evidenceDrawer [class*="ant-drawer-title"] h2 {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--bdo-scale-sm) var(--bdo-scale-md);
}

.headingWrapper h2 {
  margin: 0;
}

@media (min-width: 400px) and (max-width: 480px) {
  .evidenceDrawer [class*="ant-drawer-title"] h2 {
    max-width: 320px;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .evidenceDrawer [class*="ant-drawer-title"] h2 {
    max-width: 260px;
  }
}

.crossIconStyles {
  font-size: var(--bdo-scale-xl);
  font-weight: 700;
  cursor: pointer;
}
.pdfContainerSider {
  width: max-content;
  overflow-x: auto;
  display: inline-block;
  white-space: nowrap;
  background-color: white;
}

@media (max-width: 1030px) {
  .pdfContainerSider {
    width: 380px;
    flex: 1;
  }
}

@media (min-width: 1030px) and (max-width: 1200px) {
  .pdfContainerSider {
    max-width: 440px;
  }
}

.pdfContainerSider {
  width: max-content;
  overflow-x: auto;
  display: inline-block;
  white-space: nowrap;
  background-color: white;
}
.pdfIframeSider {
  flex: 1;
  width: 100%;
  min-width: 600px;
  height: calc(100vh - 206px) !important;
  background-color: white;
  overflow-x: auto;
}

@media (max-width: 1100px) {
  .pdfContainerSider {
    width: 100%;
    flex: 1;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .pdfContainerSider {
    max-width: 460px;
  }
}
@media (min-width: 1200px) and (max-width: 2000px) {
  .pdfContainerSider {
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 2000px) {
  .pdfContainerSider {
    max-width: 100%;
    width: 100%;
  }
}

.pdfContainerDrawer {
  width: 500px;
}
.pdfIframeDrawer {
  width: 100%;
  height: 88vh;
}
.pdfHeaderStyles {
  font-size: var(--bdo-scale-lg) !important;
  color: var(--bdo-charcoal) !important;
}
