@charset "utf-8";
/* bdo-style-modules/bdo-base-styles.css */
/* Global styling for HTML pages, no classes */

/* 
Overall goals of the Design System:
- Looks great, has a number of different looks (depending on context) and is standards compliant and responsive.
- Standalone, does not require dependencies on external frameworks
- Lightweight, has minimal footptint and load times
- Simple: With minimal work on behalf of the developer, things look good and work as expected.
- Plays nicely with Twitter Bootstrap components and classes without conflict.
- bdo-settings.css can be streamed into other frameworks (such as Ant-d and surveyJS) with minimal effort.
*/

*,
*:before,
*:after {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  accent-color: var(--bdo-colour-accent-primary);
}
html {
  scroll-behavior: smooth;
}
html,
body,
#root {
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
}
body,
#root {
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  font-family: var(--bdo-font-primary);
  font-size: var(--bdo-md);
  color: var(--bdo-colour-primary);
  line-height: 1.5;
  text-align: left;
  background-color: white;
  background-image: var(--bdo-gradient-light);
  background-attachment: fixed;
}
main {
  flex: 1;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  margin-bottom: var(--bdo-sm);
}

h1,
h2 {
  font-size: var(--bdo-xxxl);
}

h3,
h4,
h5,
h6 {
  font-size: var(--bdo-md);
  font-weight: var(--bdo-text-medium) !important;
}
h4 {
  color: var(--bdo-colour-accent-primary);
}
h5 {
  color: var(--bdo-colour-accent-secondary);
}
p {
  line-height: 1.5;
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
p a {
  color: var(--bdo-colour-hyperlink);
}

p a {
  text-decoration: underline;
}

p a:hover {
  color: var(--bdo-colour-primary);
}

br {
  line-height: 1em;
}

nav ul,
nav menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-direction: column;
}

hr {
  border-color: var(--bdo-shade-dark);
}

hr + :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--bdo-xxxl);
}

blockquote {
  border: 1px solid var(--bdo-shade);
  padding: var(--bdo-md) var(--bdo-xxl);
  background-color: white;
  box-shadow: var(--bdo-drop-shadow);
  border-left: var(--bdo-xxs) solid var(--bdo-colour-accent-primary);
}

button {
  background-color: var(--bdo-colour-background);
  border: 1px solid var(--bdo-shade);
  width: auto;
  padding: var(--bdo-xxs) var(--bdo-xl);
  margin-right: var(--bdo-md);
}

button:last-of-type {
  /* margin-left: auto; */
  margin-right: 0;
}

pre {
  overflow: auto;
}

pre > code {
  display: block;
  word-wrap: normal;
}

/* disabled states */
*:disabled {
  opacity: 0.8;
}

/* transition elements */
a,
button,
input,
li {
  transition: 0.3s;
}
