.badgeStyles {
  line-height: normal !important;
  margin: 0px !important;
  color: var(--sub-text) !important;
  cursor: pointer;
}
.badgeStyles [class*="ant-badge-dot"] {
  background-color: var(--bdo-primary) !important;
  bottom: -8px !important;
  top: auto !important;
  width: 10px !important;
  height: 10px !important;
}
.feedbackIconStyles {
  cursor: pointer;
  color: var(--sub-text) !important;
}
