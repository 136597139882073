.contentWrapper {
  max-width: 295px;
  min-width: 280px;
}
.contentWrapper .mainHeading {
  font-size: var(--bdo-scale-md);
  font-weight: var(--bdo-text-bold);
  color: var(--bdo-charcoal);
  margin: 0;
  margin-bottom: var(--bdo-scale-xxs);
}
.contentWrapper .textStyles {
  font-size: var(--bdo-scale-md);
  font-weight: var(--bdo-text-base);
  color: var(--bdo-charcoal);
  margin: 0;
  margin-bottom: 8px;
}
.contentWrapper [class*="ant-divider"] {
  margin: 0;
  margin-bottom: 8px;
}
