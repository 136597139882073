.TextAreaIconsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--bdo-semi-transparent-lighter);
  margin-left: 30px;
  margin-right: 30px;
}

.textAreaInputStyles {
  max-width: 90% !important;
  border: transparent;
}
.textAreaInputStyles:focus,
.textAreaInputStyles:active,
.textAreaInputStyles:hover {
  box-shadow: none;
  transition: none;
  border: transparent;
}

.tagItemsWrapper {
  display: flex-block;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--bdo-scale-sm);
  padding: var(--bdo-scale-xs);
  padding-top: 0px !important;
  border-top: 1px solid var(--bdo-semi-transparent-lighter);
  border-left: 1px solid var(--bdo-semi-transparent-lighter);
  border-right: 1px solid var(--bdo-semi-transparent-lighter);
  gap: var(--bdo-scale-lg);
}
.imageTagItemsWrapper {
  display: flex-block;
  width: 100%;
  margin-top: var(--bdo-scale-sm);
  border: 1px solid var(--bdo-semi-transparent-lighter);
}
.infoText {
  margin: 0;
  color: var(--bdo-secondary);
  font-size: 12px !important;
  margin: var(--bdo-scale-xxs) 0px;
}
.uploadLoadingIconStyles {
  align-self: flex-end;
  margin-bottom: var(--bdo-scale-sm);
  margin-left: var(--bdo-scale-xs);
}
.loadingIconStyles {
  align-self: flex-end;
  margin-bottom: var(--bdo-scale-xxs);
  margin-right: var(--bdo-scale-xs);
  padding-bottom: var(--bdo-scale-xxxs);
}
.sendIconStyles {
  align-self: flex-end;
  color: var(--sub-text);
  padding: var(--bdo-scale-xs);
}
.paperClipStyles {
  align-self: flex-end;
  padding-left: var(--bdo-scale-xs);
}
.textAreaInputStyles {
  padding-right: 10px;
}
.textAreaInputStyles::placeholder {
  color: var(--sub-text-light);
}
.paperClipIcon:hover {
  color: var(--bdo-secondary) !important;
}
.paperClipIcon {
  padding-top: var(--bdo-scale-xs);
  padding-bottom: var(--bdo-scale-xs);
  padding-right: var(--bdo-scale-xs);
}
.flexWrapperContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: var(--bdo-scale-xxs) var(--bdo-scale-xxxs);
}
.iconLeftMargin {
  margin-left: 20px;
  padding-left: 10px;
}
.listeningMessageContainer {
  position: relative;
  width: 100%;
}
.listeningMessage {
  color: var(--bdo-primary) !important;
  font-size: var(--bdo-scale-md);
  font-family: "Proxima Nova", sans-serif;
  margin-bottom: var(--bdo-scale-xxs);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bullet {
  margin-right: 5px;
  font-size: 21px;
  font-weight: bold;
  padding-bottom: 3px;
  padding-right: 3px;
}
.iconWrapper {
  display: flex;
  align-self: flex-end;
  height: 100%;
}
.badgeDot {
  display: flex;
  align-items: center;
  .ant-badge-status-dot {
    background-color: var(--bdo-primary) !important;
    margin-right: 8px;
  }
}
.badgeDot {
  [class*="ant-badge-status-text"] {
    color: var(--bdo-primary) !important;
  }
}
.errorAlertStyles {
  width: 100%;
  color: var(--bdo-error);
  background-color: var(--bdo-error-lightest);
  border: none !important;
  margin-bottom: var(--bdo-scale-md);
  padding: var(--bdo-scale-md);
}
.errorAlertIcon {
  transform: rotate(180deg);
  margin: 0.5px;
  margin-right: var(--bdo-scale-xxs) !important;
}
.infoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.infoText {
  margin: 0;
  color: var(--bdo-secondary);
  font-size: var(--bdo-scale-sm);
  margin: var(--bdo-scale-xxs) 0px;
}

.downloadButton {
  font-size: 12px;
}
.downloadConversationWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.notificationLoadingIconStyles {
  color: var(--bdo-primary) !important;
}

[class*="ant-notification-notice-wrapper"] {
  border-radius: 0px !important;
  padding: 0px !important;
  width: 390px;
  border: 1px solid var(--bdo-mono-lighter) !important;
}

[class*="ant-notification-notice-message"] {
  margin-bottom: 0px !important;
}

.fileUploadLoadingSpinnerStyles {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  gap: var(--bdo-scale-xxxs);
}
