.enterTextSectioWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px !important;
  margin-left: 30px;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .enterTextSectioWrapper {
    margin-left: var(--bdo-scale-lg) !important;
    margin-right: var(--bdo-scale-lg);
  }
}
.sectionWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageWelcometext {
  margin-top: 0;
  font-size: var(--bdo-scale-lg);
  font-weight: 900 !important;
  color: var(--bdo-secondary) !important;
  width: 260px;
  text-align: left;
}
.chatWrapper {
  justify-content: center;
}
.chatBgImageWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.chatBgImage {
  height: 122.5px;
}

.imageLableContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 224px;
}
.newMessageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconLoaderWrapper {
  width: 100%;
  max-width: 800px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loaderStyles {
  padding: 2rem 5rem 2rem 2rem;
}
.avatarWrapper {
  background-color: white;
  border: 1px solid var(--bdo-charcoal-light);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}
.iconCheck {
  color: var(--bdo-success);
}
.iconClose {
  color: var(--bdo-primary);
}

[class*="ant-notification-notice-wrapper"] {
  border-radius: 0px !important;
  padding: 0px !important;
  width: 390px;
  border: 1px solid var(--bdo-mono-lighter) !important;
}

[class*="ant-notification-notice-message"] {
  margin-bottom: 0px !important;
}
