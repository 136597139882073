* {
  background-repeat: no-repeat;
}
a:focus,
a:focus-within {
  outline: none;
  box-shadow: none;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  main {
    max-height: max-content !important;
  }
}

/* card mods */
[class*="bdo-card"] :is(.bdo-card-header, .bdo-card-footer) {
  height: auto;
  padding: var(--bdo-xxs) var(--bdo-md);
  flex-direction: row;
}

[class*="bdo-card"] > .bdo-card-header :is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
  font-size: var(--bdo-sm);
  font-weight: var(--bdo-text-medium) !important;
  color: var(--bdo-colour-primary);
}

.bdo-warning {
  background-color: var(--bdo-gold-light);
}

.policyLinks {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.policyLinks:hover {
  color: grey !important;
  font-weight: bold;
  text-decoration: underline;
}

.policyLinks:visited {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

/* Custom mobile responsive header */
@media only screen and (max-width: 1000px) {
  main {
    max-height: max-content !important;
  }

  article {
    min-width: 0px !important;
  }
}

.chat-log {
  /* overflow: visible; */
  max-height: fit-content !important;
  flex: 1;
}

.chat-message.chatgpt {
  background-color: var(--bdo-shade-light);
  margin-left: 0;
}

.chat-message-center {
  padding: 12px;
  outline: none;
  margin: 0 auto;
  border-bottom: 1px solid #e7e7e7;
}

@media only screen and (max-width: 768px) {
  .chat-message-center {
    padding: 2rem var(--bdo-scale-lg) 2rem var(--bdo-scale-lg);
  }
}

.message {
  padding: 4px var(--bdo-xxl);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  flex: 1;
  word-break: break-word;
}
.message p,
.message h1,
.message h2,
.message h3,
.message h4,
.message h5,
.message h6 {
  margin-top: 0;
}
.bdo-pane-top {
  overflow-x: hidden;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.custom-persona-exists {
  color: var(--bdo-primary, yellow) !important;
}
