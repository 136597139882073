.adminAddEditUpdateStyles {
  background-color: var(--mono-lightest) !important;
}

.adminAddEditUpdateStyles [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
  color: var(--bdo-charcoal);
}
.adminAddEditUpdateStyles [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.adminAddEditUpdateStyles [class*="ant-drawer-header"] {
  border: none !important;
  padding: var(--bdo-scale-md) !important;
  padding-bottom: 0 !important;
}
.adminAddEditUpdateStyles [class*="ant-drawer-header-title"] {
  padding: 0 !important;
}
.adminAddEditUpdateStyles
  [class*="ant-drawer-wrapper-body"]
  [class*="ant-drawer-body"] {
  display: flex;
  flex-direction: column;
  padding: var(--bdo-scale-md) !important;
}

.adminAddEditUpdateStyles .submitButton {
  width: 100% !important;
  margin-top: var(--bdo-scale-md);
}
.adminAddEditUpdateStyles .submitButton:disabled {
  opacity: 60%;
  background-color: var(--bdo-primary);
  color: white;
}

.infoLabel {
  margin: 0px;
  margin-bottom: 8px;
}
.adminAddEditUpdateStyles .contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: full;
  flex: 1;
}
.adminFeatureListingWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bdo-scale-xs);
  max-height: 440px !important;
  margin-bottom: var(--bdo-scale-xs);
}
.adminFeatureListingWrapperOverflowAuto {
  overflow: auto;
}
.adminFeatureListingWrapperOverflowHidden {
  overflow: hidden;
}
.adminRemoveFeatureButton {
  position: absolute;
  background-color: transparent !important;
  top: 50%;
  right: 0px;
  transform: translate(-10%, -50%);
  color: var(--bdo-primary);
}
.adminRemoveFeatureButton:hover {
  color: var(--bdo-primary-dark) !important;
}

.itemContentWrapper {
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
}
.featureInputStyles {
  flex: 1 !important;
}

.adminFeaturesTextareaStyles {
  border: 1px solid var(--mono-lighter) !important;
  padding: 9px 36px 9px var(--bdo-scale-md);
  line-height: 1.5 !important;
}
.adminFeaturesTextareaStyles::placeholder {
  color: var(--sub-text) !important;
  font-weight: 400;
  font-family: "Proxima Nova Rg" !important;
}
.adminFeaturesTextareaStyles:focus,
.adminFeaturesTextareaStyles:active,
.adminFeaturesTextareaStyles:hover {
  box-shadow: none !important;
  transition: none !important;
}
.releaseVersionInputStyles {
  padding-left: 78px !important;
}
.updatesInputWrapper {
  position: relative;
  width: 100%;
}
.versionInputFieldPrefixStyles {
  position: absolute;
  left: 10px;
  top: 38px;
  border-right: 1px solid var(--mono-lighter);
  padding: 0px;
  padding-right: 10px;
  color: var(--bdo-charcoal);
}
