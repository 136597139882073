.bdoTable {
  border: 1px solid var(--mono-lighter) !important;
  thead th {
    white-space: nowrap !important;
    padding-top: var(--bdo-scale-md) !important;
    padding-bottom: var(--bdo-scale-md) !important;
    font-weight: var(--bdo-text-bold) !important;
    color: var(--bdo-mono-darke) !important;
  }

  tbody tr {
    td:has([class*="ant-empty"]) {
      background-color: var(--bdo-white) !important;
    }

    td {
      padding: var(--bdo-scale-md) var(--bdo-scale-xl);
      word-break: break-word !important;

      span.createdReviewedBy {
        word-break: normal !important;
      }
    }
  }

  [class*="ant-table-expanded-row"] [class*="ant-table-cell"] {
    background-color: var(--bdo-white) !important;
    padding: 0 !important;
  }
  [class*="ant-table-cell-row-hover"] {
    background-color: var(--bdo-white) !important;
  }
  [class*="ant-table-content"] {
    overflow-y: auto !important;
    max-height: 320px;
    min-height: 320px;
  }
  [class*="ant-table-pagination"] {
    background: var(--bdo-white);
    margin: 0 !important;
    padding: var(--bdo-scale-sm);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  [class*="ant-pagination-jump-prev"],
  [class*="ant-pagination-jump-next"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  [class*="ant-pagination-item"] {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  [class*="ant-pagination-item"] a {
    color: var(--bdo-mono-darker);
  }
  [class*="ant-pagination-item-active"] {
    border-color: var(--bdo-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    background-color: transparent;
  }
  [class*="ant-pagination-item-active"] a {
    color: var(--bdo-primary);
  }

  [class*="ant-table-body"] {
    min-height: 310px;
  }
  [class*="ant-pagination-options"] {
    [class*="ant-select-selector"] {
      border-radius: 0 !important;
      padding: var(--bdo-scale-sm) var(--bdo-scale-md);
      border: 1px solid var(--bdo-mono-lighter);
      &:hover {
        background-color: var(--bdo-mono-lightest) !important;
        color: var(--bdo-mono-dark) !important;
        outline: none !important;
      }
      &:active {
        background-color: var(bdo-mono-lighter) !important;
        color: var(--bdo-mono-darker) !important;
        outline: none !important;
      }
      &:focus {
        outline-width: 2px !important;
        outline-color: var(--bdo-info-lighter) !important;
        outline: none !important;
      }
    }
    [class*="ant-select-selector"] {
      border-radius: 0 !important;
      padding: var(--bdo-scale-sm) var(--bdo-scale-md);
      border: 1px solid var(--bdo-mono-lighter);
      &:hover {
        background-color: var(--bdo-mono-lightest) !important;
        color: var(--bdo-mono-dark) !important;
        outline: none !important;
      }
      &:active {
        background-color: var(bdo-mono-lighter) !important;
        color: var(--bdo-mono-darker) !important;
        outline: none !important;
      }
      &:focus {
        outline-width: 2px !important;
        outline-color: var(--bdo-info-lighter) !important;
        outline: none !important;
      }
    }
    [class*="ant-select-dropdown"] {
      border-radius: 0 !important;
      [class*="ant-select-item-option"] {
        border-radius: 0 !important;
      }
    }
  }

  [class*="ant-select-item-option-selected"] {
    background-color: var(--bdo-mono-lightest) !important;
    color: var(--bdo-mono-dark) !important;
  }
}

[class*="ant-table-filter-dropdown-btns"] {
  button {
    border-radius: 0 !important;
    box-shadow: none !important;

    &:not(:disabled) {
      background-color: var(--bdo-secondary) !important;
      color: var(--bdo-white) !important;
      padding-left: 10px !important;
      padding-right: 10px !important;

      &:hover {
        opacity: 0.8 !important;
        transition: opacity 0.2s ease-in-out !important;
      }
    }
  }
}

[class*="ant-dropdown-menu-item-selected"] {
  span {
    color: var(--bdo-white) !important;
  }

  span[class*="ant-checkbox-inner"] {
    background-color: var(--bdo-white) !important;
    border-color: var(--bdo-secondary) !important;

    &:after {
      border-color: var(--bdo-secondary) !important;
    }
  }
}
[class*="ant-table-measure-row"] {
  td {
    padding: 0 !important;
  }
}
