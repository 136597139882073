.tableLoaderStyles {
  color: var(--bdo-primary) !important;
}
.tableWrapper {
  overflow-y: auto;
}
.actionItemsContainer {
  display: flex;
  align-items: center;
}
.actionItemsStyles {
  width: auto !important;
  padding: var(--bdo-xs) !important;
}
.descriptionStyles {
  margin: 0 !important;
}
