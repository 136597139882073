.updateStyles {
  background-color: var(--mono-lightest) !important;
}

.updateStyles [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
}
.updateStyles [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.updateStyles [class*="ant-drawer-header"] {
  border: none !important;
  padding-bottom: 0 !important;
}
.updatesPanel [class*="ant-collapse-content-box"] {
  background-color: var(--mono-lightest);
  padding: var(--bdo-scale-sm) var(--bdo-scale-md) !important;
}
.listStyles {
  margin: 0;
  color: var(--bdo-charcoal) !important;
  line-height: 1.5 !important;
}
