[class*="ant-drawer-content"] {
  background-color: var(--mono-lightest) !important;
}
.settingsDrawer [class*="anticon-close"] {
  color: var(--bdo-charcoal);
}
.settingsDrawer [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
}
.settingsDrawer [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.accountSettingsLable {
  margin-top: 6.5px;
}
.settingsDrawer [class*="ant-drawer-header"] {
  border: none !important;
  padding-bottom: 0 !important;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
}
.settingsItemLabel {
  font-size: var(--bdo-scale-sm);
  color: var(--sub-text);
  font-weight: var(--bdo-text-base);
}
.settingsOptionWrapper {
  padding: var(--bdo-scale-sm) var(--bdo-scale-md);
  border: 1px solid var(--bdo-charcoal-light);
  margin: var(--bdo-scale-md) 0;
}
.accountSettingsOptionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.iconTextWrapper {
  display: flex;
  align-items: center;
}
.iconTextWrapper p {
  margin: 0;
  margin-left: 7.5px;
}
.modelTypeSelectionWrapper {
  padding: var(--bdo-scale-sm) var(--bdo-scale-md);
  border: 1px solid var(--bdo-charcoal-light);
  margin: 7.5px 0;
  cursor: pointer;
}
.bdoVoiceWwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchWrapper {
  display: flex;
  align-items: center;
}
.activeTextStyles {
  margin: 0 0 0 10px;
}
.tickIconStyles {
  color: var(--bdo-primary) !important;
}
