.avatarWrapper {
  background-color: white;
  border: 1px solid var(--bdo-charcoal-light);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}
.animatedElipse {
  font-size: var(--bdo-scale-xxl);
  margin-left: var(--bdo-scale-sm);
}
.newMessageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bdo-shade-light);
}
.iconLoaderWrapper {
  width: 100%;
  max-width: 800px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loaderStyles {
  padding: 2rem 5rem 2rem 2rem;
}
