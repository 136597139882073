.tagPlaceholderItemsWrapper {
  display: flex-block;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--bdo-scale-sm);
  padding: var(--bdo-scale-md);
  padding-bottom: var(--bdo-scale-xl) !important;
  border-top: 1px solid var(--bdo-semi-transparent-lighter);
  border-left: 1px solid var(--bdo-semi-transparent-lighter);
  border-right: 1px solid var(--bdo-semi-transparent-lighter);
  gap: var(--bdo-scale-lg);
}
.tagWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 600px;
  overflow: auto;
  gap: var(--bdo-scale-lg);
}
.tagWrapper::-webkit-scrollbar {
  display: none;
}
.fileUploadingOverlayLoaderStyles {
  display: flex;
  width: 63px;
  height: 53px;
  justify-content: center;
  align-items: center;
  gap: var(--bdo-scale-sm);
  background: var(--bdo-shade-dark);
  border: 1px solid var(--bdo-semi-transparent-lighter);
}

.previewImageStyles {
  width: 63px;
  height: 53px;
  border: 1px solid var(--bdo-semi-transparent-lighter);
  max-height: var(--bdo-scale-6xl);
}

.previewImageFileStyles {
  width: 63px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bdo-semi-transparent-lighter);
}

.filePreviewStyles {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
