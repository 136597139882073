.BDONavbar {
  padding: var(--bdo-scale-md) var(--bdo-scale-lg);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--mono-lighter);
}
@media (max-width: 856px) {
  .BDONavbar {
    padding-left: var(--bdo-scale-lg);
    padding-right: var(--bdo-scale-sm);
  }
}
.personaImage {
  height: var(--bdo-scale-5xl);
  width: var(--bdo-scale-5xl);
}
.adminPersonaImage {
  cursor: pointer;
  height: var(--bdo-scale-5xl);
  width: var(--bdo-scale-5xl);
}
.brandingWrapper {
  display: flex;
  align-items: center;
}
.brandText {
  font-size: 26px !important;
  font-style: normal;
  font-weight: var(--bdo-text-extrabold) !important;
  margin: 0 var(--bdo-scale-sm) !important;
  padding: 0;
  text-transform: uppercase;
  font-family: "Bliss Pro";
  border: none !important;
  line-height: 18px !important;
}
.preview {
  border: 1px dashed var(--bdo-secondary);
  color: var(--bdo-secondary);
  text-transform: uppercase;
  margin: 0;
  font-weight: var(--bdo-text-bold);
  padding: 0 var(--bdo-scale-xxs);
  height: 20px;
  font-size: var(--bdo-scale-sm);
  display: flex;
  align-items: center;
}
.divider {
  height: var(--bdo-scale-xxxl);
  margin: 0 var(--bdo-scale-xxxl);
  border-inline-start: 1px solid var(--mono-lighter);
}
.iconButtonsSections {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.chatIcon {
  color: var(--bdo-primary) !important;
}
.chatButtonStyles {
  color: var(--bdo-primary) !important;
}
.chatButtonStyles:hover {
  color: var(--bdo-primary) !important;
}
