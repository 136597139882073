.subTitleStyles {
  margin: 0;
  margin-bottom: var(--bdo-scale-lg);
  max-width: 70ch;
}
.footerWrapper {
  margin-top: var(--bdo-scale-xxl);
}
[class*="ant-modal-header"] {
  margin-bottom: var(--bdo-scale-xxxs) !important;
}
[class*="ant-modal-content"] {
  padding: var(--bdo-scale-xl) !important;
}
[class*="ant-modal-close"] {
  top: var(--bdo-scale-xl) !important;
}

[class*="ant-modal-header"] [class*="ant-modal-title"] {
  color: var(--bdo-charcoal) !important;
  font-size: 21px !important;
  font-weight: 700 !important;
}
.modalContentWrapperStyles {
  max-height: 400px;
  overflow: auto;
}
