.accountDropdown {
  border: none !important;
}
.settingsDropdownOverlay {
  width: 220px;
}

.settingsDropdownOverlay [class*="ant-dropdown-menu-item-group-title"] {
  font-size: var(--bdo-scale-sm);
}
[role="presentation"] {
  margin: 0 !important;
}
[role="menuitem"] {
  padding: var(--bdo-scale-sm) var(--bdo-scale-lg) !important;
}
.settingsDropdownOverlay [class*="ant-dropdown-menu-item-group-list"] {
  padding: 0 !important;
  margin: 0 !important;
}

.settingsDropdownOverlay [class*="ant-dropdown-menu-item"]:focus,
.settingsDropdownOverlay [class*="ant-dropdown-menu-item"]:active {
  border: none !important;
  outline: none !important;
}

.bdoVoiceWwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchWrapper {
  display: flex;
  align-items: center;
}
.activeTextStyles {
  margin: 0 0 0 10px;
}
.modelTextStyles {
  margin: 0;
}
.tickIconStyles {
  color: var(--bdo-primary) !important;
}
