@keyframes alternatingPulse {
  0%,
  100% {
    fill: #f8bac4;
  }
  25%,
  75% {
    fill: #f48d9d;
  }
  50% {
    fill: #e81a3b;
  }
}

.pulse1,
.pulse2 {
  animation: alternatingPulse 4s infinite;
}

.pulse1 {
  animation-delay: 0s;
}

.pulse2 {
  animation-delay: 2s;
}
