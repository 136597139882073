.mainLayout {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: -webkit-fill-available;
}
.mainLayout [class*="ant-layout"] {
  overflow: hidden;
}
.layoutContent {
  flex: 1;
  display: flex;
  overflow: auto;
  margin-bottom: 4px;
}
