.addPersona {
  background-color: var(--mono-lightest) !important;
}

.addPersona [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
  color: var(--bdo-charcoal);
}
.addPersona [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.addPersona [class*="ant-drawer-header"] {
  border: none !important;
  padding: var(--bdo-scale-md) !important;
  padding-bottom: 0 !important;
}
.addPersona [class*="ant-drawer-header-title"] {
  padding: 0 !important;
}
.addPersona [class*="ant-drawer-wrapper-body"] [class*="ant-drawer-body"] {
  padding: var(--bdo-scale-md) !important;
}

.addPersona .submitButton {
  width: 100% !important;
  margin-top: var(--bdo-scale-md);
}
.addPersona .submitButton:disabled {
  opacity: 60%;
  background-color: var(--bdo-primary);
  color: white;
}
.addPersonaTextAreaInputStyles {
  min-height: 460px !important;
  height: 100%;
  padding: var(--bdo-scale-sm);
  font-size: var(--bdo-scale-md);
  border: 1px solid var(--bdo-charcoal-light);
  font-family: "Proxima Nova Rg" !important;
  color: var(--bdo-charcoal);
}
.addPersonaTextAreaInputStyles:focus,
.addPersonaTextAreaInputStyles:active,
.addPersonaTextAreaInputStyles:hover {
  border: 1px solid var(--bdo-charcoal-light);
  box-shadow: none !important;
  outline: none !important;
}
.infoLabel {
  margin: 0px;
  margin-bottom: 8px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: full;
  flex: 1;
}
.perosnaEditInputStyles [class*="ant-input-disabled"] {
  color: var(--bdo-charcoal) !important;
  opacity: 0.7 !important;
}
