.loadingContainer {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.loadingSpinner div {
  color: var(--bdo-red);
}