.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.gap4 {
  gap: 0.25rem;
}

.gap8 {
  gap: 0.5rem;
}

.gap12 {
  gap: 0.75rem;
}

.gap16 {
  gap: 1rem;
}

.gap24 {
  gap: 1.5rem;
}

.gap32 {
  gap: 2rem;
}

main {
  height: auto !important;
  max-height: 100% !important;
}
ol,
ul {
  padding-left: 1em;
}

.personas .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ed1a3b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333 !important;
  font-weight: var(--bdo-text-bold) !important;
}
.ant-layout-sider {
  background: transparent !important;
}
.limitError .ant-alert {
  background-color: var(--bdo-red-light) !important;
  padding: var(--bdo-xs) var(--bdo-xxl) !important;
  font-size: var(--bdo-s) !important;
  line-height: 1.5;
  border-radius: 0px !important;
}

.limitError .ant-alert-message {
  color: #333333 !important;
}

.limitError .ant-alert-close-icon .anticon-close {
  color: #333333 !important;
}

.noResize {
  resize: none !important;
  overflow: auto !important;
}

.feedbackInput {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
}

.userPersona {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
}

.justify-spaced {
  justify-content: space-between;
}

.actionNotifications {
  display: flex;
  gap: 2px;
  align-items: center;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #0062b8;
  transition: 0s all ease-in !important;
  border-bottom: 2px solid #0062b800;
  cursor: pointer;
  font-size: 12px;
}

.icon-button:hover {
  color: #0062b8;
  border-bottom: 2px solid #0062b8;
}

.icon-button label {
  user-select: none;
  cursor: pointer;
}

.destructive {
  color: #98002e;
}

.destructive:hover {
  color: #98002e;
  border-bottom: 2px solid #98002e;
}

.persona-actions {
  gap: 12px;
  padding: 0.5rem 1rem;
  border-top: 1px solid rgb(51 51 51 / 0.15);
}

.errorMessageSection {
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bdo-right-sidebar-content {
  background: #f2f2f2;
  padding: var(--bdo-md) var(--bdo-md) var(--bdo-sm) var(--bdo-md);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 1rem;
  height: 100%;
  overflow: scroll;
}

.content-area {
  background: #fff;
}

header button {
  margin: 0px !important;
}

.terms-modal {
  padding: 1rem;
  padding-bottom: 0;
}

.terms-modal a {
  color: #0062b8;
}

.terms-modal a:hover {
  color: rgb(8, 72, 129) !important;
}

.terms-modal .error-message {
  font-size: 1rem;
  color: #ed1a3b;
}

.terms {
  font-size: 1rem;
  border: 1px solid #f2f2f2;
  padding: 1rem;
  list-style: none;
}

.terms li {
  margin: 1rem;
  color: #333;
}
pre {
  background: #fff;
  color: var(--bdo-charcoal);
  font-family: monospace;
  padding: var(--bdo-scale-md) var(--bdo-scale-sm);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  flex: 1 1;
  word-break: break-word;
}
.terms {
  max-height: 280px;
  overflow-y: scroll;
}
.terms-header {
  margin: 0;
}
.ant-select-selector:hover {
  border: 1px solid var(--bdo-charcoal-light) !important;
  outline: none !important;
}
.ant-select-selector {
  border: 1px solid var(--bdo-charcoal-light) !important;
}
/* Remove border and outline on focus */
.ant-select-focused .ant-select-selector {
  border: 1px solid var(--bdo-charcoal-light) !important;
  outline: none !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 2px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
