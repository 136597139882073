.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bdo-scale-xxs);
  font-family: "Proxima Nova Rg";
}
.inputStyles {
  display: flex;
  padding: 9px var(--bdo-scale-md);
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid var(--bdo-semi-transparent-lighter);
  background: var(--bdo-white);
  font-family: "Proxima Nova Rg";
  color: var(--bdo-charcoal);
}
.inputStyles:disabled {
  cursor: not-allowed;
  color: var(--bdo-charcoal);
}
.inputStyles:focus,
.inputStyles:focus-visible,
.inputStyles:focus-within,
.inputStyles:active {
  border: 1px solid var(--bdo-semi-transparent-light) !important;
  outline: none;
  box-shadow: none !important;
}

.inputStyles:hover {
  border: 1px solid var(--bdo-semi-transparent-lighter);
}
.inputStyles::placeholder {
  color: var(--sub-text) !important;
  font-weight: 400;
  font-family: "Proxima Nova Rg" !important;
}
.inputInfoStyles {
  font-size: var(--bdo-scale-sm);
  color: var(--sub-text);
  margin: 0;
}
