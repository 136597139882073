.personaSelection {
  background-color: var(--mono-lightest) !important;
}

.personaSelection [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
  color: var(--bdo-charcoal);
}
@media screen and (max-width: 768px) {
  .personaSelection [class*="ant-drawer-body"] {
    padding: var(--bdo-scale-xl) var(--bdo-scale-xxs) !important;
  }
}
.personaSelection [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.personaSelection [class*="ant-drawer-header"] {
  border: none !important;
  padding-bottom: 0 !important;
}

.personas {
  width: 100%;
}
.personas:focus,
.personas:hover {
  border: none !important;
  outline: none !important;
}
.sharefeedback [class*="anticon-close"] {
  color: var(--bdo-charcoal);
}
.personas .ant-select-selector:focus,
.personas .ant-select-selector:hover {
  border: none !important;
  outline: none !important;
}

.personas .ant-select-selection-placeholder {
  color: var(--bdo-charcoal);
}
.personas .ant-select-selection-item {
  color: var(--bdo-charcoal);
}
.bdoPersonaCard textarea {
  flex: 1;
  border: none;
  font-size: var(--bdo-sm);
  padding: var(--bdo-md);
  font-family: "Proxima Nova Rg" !important;
  color: var(--bdo-charcoal);
}

.bdoPersonaCard {
  flex: 1;
  margin-bottom: 0px !important;
}
.personas .anticon {
  color: var(--bdo-charcoal);
}
.dropdownIcon {
  color: var(--bdo-charcoal);
  pointer-events: none !important;
}
.personaImage {
  height: 35px;
  width: 35px;
}

.personalDetailsheading {
  font-weight: var(--bdo-text-base) !important;
  font-size: var(--bdo-scale-sm) !important;
}
.bdoPersonaTypeName {
  font-size: var(--bdo-scale-md);
  font-weight: var(--bdo-text-bold);
  font-family: "Bliss Pro";
}
.personaSelectionHeading {
  font-size: var(--bdo-scale-lg);
  margin: 0;
  margin-right: 4px;
  margin-bottom: 4px;
}
.personaHeaderWrapper {
  display: flex;
  align-items: center;
}
.personaHeaderInfoIcon {
  padding: 1px 1px 2px 0;
}
.saveAsStyles {
  font-size: var(--bdo-scale-md) !important;
  font-weight: var(--bdo-text-base);
  color: var(--bdo-link) !important;
}
.saveAsStyles:hover {
  font-size: var(--bdo-scale-md) !important;
  font-weight: var(--bdo-text-base);
  color: var(--bdo-link-dark) !important;
}
.saveAsText {
  margin-left: 4px;
  border: none !important;
}
.saveAsStyles:hover {
  border-color: transparent !important;
}

.textAreaInputStyles {
  padding-top: 8px;
  font-size: var(--bdo-scale-md) !important;
  font-family: "Proxima Nova Rg" !important;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--bdo-charcoal);
}
.textAreaInputStyles:focus,
.textAreaInputStyles:active {
  border: 1px solid var(--bdo-charcoal-light);
  box-shadow: none;
  transition: none;
  outline: none !important;
}
.personalNameInputStyles {
  border: 1px solid var(--bdo-charcoal-light);
  width: 100%;
}
.personalNameInputStyles:focus,
.personalNameInputStyles:active {
  border: 1px solid var(--bdo-charcoal-light);
  box-shadow: none;
  transition: none;
  outline: none !important;
}
