.voiceIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.listeningMessage {
  color: var(--bdo-primary);
  font-size: var(--bdo-scale-md);
  margin-bottom: var(--bdo-scale-xxs);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.bullet {
  margin-right: 5px;
  font-size: 21px;
  font-weight: bold;
  padding-bottom: 3px;
  padding-right: 3px;
}
.icon {
  font-size: 20px;
  cursor: pointer;
  color: var(--sub-text);
  padding: 5px;
}
.closeIcon {
  background-color: #33333310;
  border-radius: 50%;
}
.audioActiveIcon:hover {
  color: var(--bdo-secondary) !important;
}
.audioActiveIcon {
  padding: var(--bdo-scale-xs);
}
.audioMutedIcon:hover {
  color: #b7122d !important;
}
.audioMutedIcon {
  color: var(--bdo-primary);
  padding: var(--bdo-scale-xs);
}
.customTooltip {
  color: #333333;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: 42px;
  width: 169px;
  margin-bottom: 25.5px;
}
