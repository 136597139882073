.DragAndDropOverlayStyles {
  width: 100%;
  height: 100%;
  position: fixed;
  background: var(--bdo-tint-lightest);
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.DragAndDropOverlayStyles.visible {
  opacity: 1;
  visibility: visible;
}

.DragAndDropOverlayStyles.hidden {
  opacity: 0;
  visibility: hidden;
}

.DragAndDropZoneStyles {
  [class*="ant-upload ant-upload-drag"] {
    display: flex;
    flex-direction: column;
    width: 528px;
    height: 430.95px;
    align-items: center;
    text-align: center;
    gap: var(--bdo-scale-lg);
    border: 2px dashed var(--bdo-shade-light-dark);
  }
}
.DragAndDropZoneStyles {
  [class*="ant-upload-drag-container"] {
    padding: 0px;
  }
}

.DragAndDropZoneStyles {
  [class*="ant-upload-wrapper ant-upload-drag ant-upload"] {
    padding: 0px;
  }
}

.DragAndDropZoneStyles.DragOverStyles {
  border: 2px dashed var(--bdo-primary);
}

.DragAndDropZoneStyles .DragAndDropZoneImageStyles {
  width: 80px;
  height: 100.95px;
  bottom: var(--bdo-scale-md);
}

.DragAndDropZoneTextAreaStyles {
  display: flex;
  flex-direction: column;
  width: 528px;
  height: 49.5px;
  gap: var(--bdo-scale-sm);
}

.DragAndDropZoneHeaderTextStyles {
  width: 528px;
  height: 25px;
  font-size: var(--bdo-scale-lg);
  font-weight: var(--bdo-text-bold);
  line-height: 24.5px;
}

.DragAndDropZoneTextStyles {
  width: 528px;
  height: 22px;
  font-size: var(--bdo-scale-md);
  font-weight: var(--bdo-text-base);
  line-height: 21px;
}
